import Styles from './AddVoucherForm.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import { TextField, FormGroup } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { object, string } from 'yup'
import { IFLoadingIndicator, IFButton } from 'Components'
import VoucherSelectors from 'Stores/Voucher/Selectors'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { DatePicker } from 'rsuite'
import { useState } from 'react'

const StyledTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: Colors.text,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: Colors.grey,
        borderWidth: 1,
      },
      '&:hover fieldset': {
        borderColor: Colors.primary,
        borderWidth: 1,
      },
      '&.Mui-focused fieldset': {
        borderColor: Colors.primary,
        borderWidth: 1,
      },
    },
  },
}))(TextField)

const initialValues = {
  voucherName: '',
  voucherCode: '',
  voucherPoints: '',
  voucherPoints: '',
  voucherMaxRedeemCountPerUser: '',
  voucherMaxRedeemed: '',
  voucherValidity: '',
}

const FormContainer = styled.div`
  background-color: ${Colors.white};
`

const ErrorContainer = styled.div`
  color: ${Colors.red};
`

const AddVoucherForm = ({ onSubmitForm, isAddVoucherLoading }) => {
  const { t } = useTranslation()
  const [selectedDate, setSelectedDate] = useState(null)
  return (
    <FormContainer className={Styles.FormWrappingDiv}>
      <div className={Styles.FieldsWrapper}>
        <Formik
          validationSchema={object({
            voucherName: string().required(t('VouchersPage.Required')),
            voucherCode: string().required(t('VouchersPage.Required')),
            voucherPoints: Yup.number().required(t('VouchersPage.Required')),
            voucherMaxRedeemed: Yup.number().required(
              t('VouchersPage.Required'),
            ),
            voucherValidity: string().required(t('VouchersPage.Required')),
          })}
          initialValues={{
            ...initialValues,
            voucherExpiry: selectedDate
              ? selectedDate.toISOString().split('T')[0]
              : undefined,
          }}
          onSubmit={(values) => {
            onSubmitForm(values)
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherName">
                  {t('VouchersPage.VoucherNameForm')}
                </label>
                <Field
                  className={Styles.Input}
                  size="small"
                  name="voucherName"
                  as={StyledTextField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherName" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherCode">
                  {t('VouchersPage.VoucherCodeForm')}
                </label>
                <Field
                  as={StyledTextField}
                  className={Styles.Input}
                  name="voucherCode"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherCode" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherPoints">
                  {t('VouchersPage.VoucherPointsForm')}
                </label>
                <Field
                  as={StyledTextField}
                  className={Styles.Input}
                  name="voucherPoints"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherPoints" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherMaxRedeemed">
                  {t('VouchersPage.VoucherMaxRedeemedForm')}
                </label>
                <Field
                  as={StyledTextField}
                  className={Styles.Input}
                  name="voucherMaxRedeemed"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherMaxRedeemed" />
                </ErrorContainer>
              </FormGroup>
              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherMaxRedeemCountPerUser">
                  {t('VouchersPage.VoucherMaxRedeemCountPerUser')}
                </label>
                <Field
                  as={StyledTextField}
                  className={Styles.Input}
                  name="voucherMaxRedeemCountPerUser"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherMaxRedeemCountPerUser" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherValidity">
                  {t('VouchersPage.ValidityForm')}
                </label>
                <Field
                  name="voucherValidity"
                  variant="outlined"
                  size="small"
                  as={StyledTextField}
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherValidity" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="voucherExpiry">
                  {t('VouchersPage.ExpiryForm')}
                </label>
                <DatePicker
                  placement="top"
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue)
                    setFieldValue(
                      'voucherExpiry',
                      newValue ? newValue.toISOString().split('T')[0] : null,
                    )
                  }}
                  cleanable={false}
                  size="lg"
                  placeholder="YYYY-MM-DD"
                  shouldCloseOnSelect={false}
                  disabledDate={(date) => date < new Date()}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="voucherExpiry" />
                </ErrorContainer>
              </FormGroup>
              <IFButton
                className={Styles.ButtonWrappingDiv}
                type="submit"
                color={Colors.primary}
                isDead={
                  !(
                    values.voucherName.length > 0 &&
                    values.voucherCode.length > 0 &&
                    values.voucherPoints.length > 0 &&
                    values.voucherMaxRedeemed.length > 0 &&
                    values.voucherValidity.length > 0
                  ) ||
                  (errors.voucherName && errors.voucherName.length > 0) ||
                  (errors.voucherCode && errors.voucherCode.length > 0) ||
                  (errors.voucherPoints && errors.voucherPoints.length > 0) ||
                  (errors.voucherMaxRedeemed &&
                    errors.voucherMaxRedeemed.length > 0) ||
                  (errors.voucherValidity && errors.voucherValidity.length > 0)
                }
                isFill={true}
                isLoading={false}
                text={
                  isAddVoucherLoading ? (
                    <div className={Styles.LoadingIndicator}>
                      <IFLoadingIndicator size={'2em'} />
                    </div>
                  ) : (
                    t('VouchersPage.Submit')
                  )
                }
              />
            </Form>
          )}
        </Formik>
      </div>
    </FormContainer>
  )
}

const mapStateToProps = (state) => ({
  isAddVoucherLoading: VoucherSelectors.getIsAddVoucherLoading(state),
})

export default connect(mapStateToProps)(AddVoucherForm)
