import { put, call } from 'redux-saga/effects'
import { vouchersApiService, handleError } from 'Services/ApiService.js'
import voucherActions from 'Stores/Voucher/Actions'
var base64 = require('base-64')

export function* fetchVouchers({ filter, offset }) {
  yield put(voucherActions.fetchVouchersListLoading())
  try {
    if (filter !== null) {
      filter = base64.encode(JSON.stringify(filter))
    }
    const { data } = yield call(vouchersApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    yield put(
      voucherActions.fetchVouchersListSuccess(
        offset,
        data.vouchers,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(voucherActions.fetchVouchersListError(e))
    handleError(e)
  }
}

export function* addVoucher({ voucher }) {
  yield put(voucherActions.addVoucherLoading())
  try {
    const { data } = yield call(vouchersApiService.post, '/', {
      voucherName: voucher.voucherName,
      voucherCode: voucher.voucherCode,
      voucherPoints: voucher.voucherPoints,
      voucherMaxRedeemed: voucher.voucherMaxRedeemed,
      voucherMaxRedeemCountPerUser: voucher.voucherMaxRedeemCountPerUser,
      voucherValidity: voucher.voucherValidity,
      voucherExpiry: voucher.voucherExpiry,
    })
    yield put(voucherActions.addVoucherSuccess(data))
  } catch (e) {
    yield put(voucherActions.addVoucherFail(e))
    handleError(e)
  }
}

export function* updateVoucher({ voucherId, newVoucher, index, onResponse }) {
  try {
    yield put(voucherActions.updateVoucherLoading())
    const { data } = yield call(vouchersApiService.patch, `/${voucherId}`, {
      ...newVoucher,
    })
    yield put(voucherActions.updateVoucherSuccess(index, data))
    onResponse(index)
  } catch (e) {
    yield put(voucherActions.updateVoucherFail(e))
    onResponse(index)
    handleError(e)
  }
}
